import React, { useState } from "react";
import "./FaqHome.css";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FaqHome() {
  const [like, setlike] = useState(245);
  const [dislike, setdislike] = useState(19);
  return (
    <div>
      <Container fluid className="py-5" id="faq-home" data-aos="fade-up">
        <div className="d-none d-md-block" id="faq-home-float-div"></div>
        <Row className=" my-5">
          <Col>
            <h1 className="">FREQUENTLY ASKED QUESTIONS</h1>
            <br />
            <h3 className="text-center ft-darkk">
              Look For Most Frequently Asked Questions
            </h3>
          </Col>
        </Row>

        <Container>
          <Row id="faq-home-cards">
            <Col md={5} id="faq-home-card1">
              <h5 className="ft-darkk">
                <span
                  className="iconify"
                  data-icon="codicon:star-empty"
                  data-inline="false"
                ></span>
                &nbsp;Popular questions
              </h5>
              <ul>
                <li>What is the warranty of Polycarbonate sheet?</li>
                <li>
                  How strong is the polycarbonate sheet in safety aspects?
                </li>
                <li>What is the thickness of the polycarbonate sheet?</li>
                <li>What is the warranty of aluminum profile?</li>
                <li>
                  What are the various shades available in aluminum profile?
                </li>
                <li>
                  What is the color variant available in polycarbonate sheet?
                </li>
              </ul>
            </Col>
            <Col md={4} id="faq-home-card2">
              <ul className="mt-md-5">
                <li>What is the overall life of the product?</li>
                <li>What is the minimum Height of the enclosure?</li>
                <li>What is the space required to place for track?</li>
                <li>Is the Roof 100% leak-proof?</li>

              </ul>
              <div className="offset-1 mb-4" id="faq-read-more" data-aos="fade-up">
                <Link to="/faq" className="btn btn-md rounded-pill">Read More</Link>
              </div>
            </Col>
            <Col md={3} id="faq-home-card3">
              <h5 className="ft-darkk">
                <span
                  className="iconify"
                  data-icon="bx:bx-link"
                  data-inline="false"
                ></span>
                &nbsp;Useful links for you
              </h5>
              <ul>
                <li>
                  <Link to="" className="faq-link">
                    <strong>Order return form</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="faq-link">
                    <strong>Contact form</strong>
                  </Link>
                </li>
                <li>
                  <Link to="" className="faq-link">
                    <strong>Shipping pricing table</strong>
                  </Link>
                </li>
                <li>
                  <Link to="" className="faq-link">
                    <strong>Reviews</strong>
                  </Link>
                </li>
                <li>
                  <Link to="" className="faq-link">
                    <strong>Your orders</strong>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="div-strip pt-3 pb-sm-0 pb-2">
        <Container>
          <Row>
            <Col sm={9}>
              <p>
                <strong>Did you find what you were looking for?</strong>
              </p>
            </Col>
            <Col sm={3}>
              {like}
              <button onClick={() => setlike(like + 1)}>
                <span
                  className="iconify"
                  data-icon="ant-design:like-outlined"
                  data-inline="false"
                ></span>{" "}
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {dislike}
              <button onClick={() => setdislike(dislike + 1)}>
                <span
                  className="iconify"
                  data-icon="ant-design:dislike-outlined"
                  data-inline="false"
                ></span>
              </button>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
