import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.css";
import "../asset/css/style.css";
import { useHistory, Link} from "react-router-dom";
import banner1 from "../asset/img/banner/banner1.jpg";
import banner2 from "../asset/img/banner/banner2.jpg";
import banner3 from "../asset/img/banner/banner3.jpg";
import banner4 from "../asset/img/banner/banner4.jpg";
import banner5 from "../asset/img/banner/banner5.jpg";
import banner6 from "../asset/img/banner/banner6.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

export default function CarouselBanner() {
  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, [])
  const History = useHistory();
  const handleClick = () => {
    History.push(`/products`)
  }
  return (
    <>
      <Carousel id="carousel-banner">
        <Carousel.Item interval={3000}>
          <img className="d-block w-100" src={banner1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img className="d-block w-100" src={banner2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img className="d-block w-100" src={banner3} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img className="d-block w-100" src={banner4} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img className="d-block w-100" src={banner5} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img className="d-block w-100" src={banner6} alt="Third slide" />
        </Carousel.Item>
      </Carousel>
      <div id="kindlySkewMe">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-5">
              <div className="card">
                <div className="card-text pl-3">
                  <div className="helper-text text-uppercase">
                    <h1>
                      <strong id="bolderme">Megavent</strong>
                    </h1>
                    <p>Redefining Skylights</p>
                    <button className="btn btn-lg" onClick={handleClick}>Know More &gt;</button>
                  </div>
                  <div className="helper">
                    <Link to="/about-us">
                      <i className="fab fa-facebook"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
