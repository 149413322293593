import React from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import "./Images.css"
import img1 from "../asset/img/our-product-home/dom.jpg"
import retroof from "../asset/img/our-product-home/ret-roof.jpg"
import Banner from "../home-components/CarouselBanner";
import imagesComData from "../json/ImagesCom.json";
import imagesResData from "../json/ImagesRes.json";
import TopBanner from "../home-components/HeaderContent.jsx";
import gimg2 from "../asset/img/Gallery/Dome/Commercial/dom2.jpg"
import gimg3 from "../asset/img/Gallery/Dome/Commercial/dom3.jpg"
import gimg4 from "../asset/img/Gallery/Dome/Commercial/dom4.jpg"
import gimg5 from "../asset/img/Gallery/Dome/Commercial/dom5.jpg"

import gimg6 from "../asset/img/Gallery/Fixed Skylight/Commercial/fixed-com1.jpeg"
import gimg7 from "../asset/img/Gallery/Fixed Skylight/Commercial/fixed-com2.jpeg"

import gimg8 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/umb-com1.jpg"
import gimg9 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/umb-com2.jpg"
import gimg10 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/umb-com3.jpg"
import gimg11 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/umb-com4.jpg"
import gimg12 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/umb-com5.jpg"
import gimg13 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/umb-com6.jpg"
import gimg14 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/ventrilla1.jpg"
import gimg15 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/ventrilla2.jpg"
import gimg16 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/ventrilla3.jpg"
import gimg17 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/ventrilla4.jpg"
import gimg18 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Commercial/ventrilla5.jpg"

import gimg19 from "../asset/img/Gallery/RETRACTABLE PATIO ENCLOSURE/Commercial/patio-com1.jpg"
import gimg20 from "../asset/img/Gallery/RETRACTABLE PATIO ENCLOSURE/Commercial/patio-com2.jpg"

import gimg21 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com1.jpg"
import gimg22 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com2.jpg"
import gimg23 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com3.jpg"
import gimg24 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com4.jpg"
import gimg25 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com5.jpg"
import gimg26 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com6.jpg"
import gimg27 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com7.jpg"
import gimg28 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com8.jpg"
import gimg29 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com9.jpg"
import gimg30 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com10.jpg"
import gimg31 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com11.jpg"
import gimg32 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com12.jpg"
import gimg33 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com13.jpg"
import gimg34 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com14.jpg"
import gimg35 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com15.jpg"
import gimg36 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com16.jpg"
import gimg37 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com17.jpg"
import gimg38 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com18.jpg"
import gimg39 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com19.jpg"
import gimg40 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com20.jpg"
import gimg41 from "../asset/img/Gallery/RETRACTABLE ROOFS/Commercial/spectra-com21.jpg"

import gimg42 from "../asset/img/Gallery/Dome/Residential/dom-res1.jpg"
import gimg43 from "../asset/img/Gallery/Dome/Residential/dom-res2.jpg"
import gimg44 from "../asset/img/Gallery/Dome/Residential/dom-res3.jpg"
import gimg45 from "../asset/img/Gallery/Dome/Residential/dom-res4.jpg"

import gimg46 from "../asset/img/Gallery/Fixed Skylight/Residential/fixed-res1.jpg"
import gimg47 from "../asset/img/Gallery/Fixed Skylight/Residential/fixed-res2.jpg"
import gimg48 from "../asset/img/Gallery/Fixed Skylight/Residential/fixed-res3.jpg"

import gimg49 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Residential/ventrilla-res.jpg"
import gimg50 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Residential/ventrilla-res2.jpg"
import gimg51 from "../asset/img/Gallery/HEAVY DUTY UMBRELLA/Residential/ventrilla-res3.jpg"

import gimg52 from "../asset/img/Gallery/RETRACTABLE PATIO ENCLOSURE/Residential/patio-res1.jpg"
import gimg53 from "../asset/img/Gallery/RETRACTABLE PATIO ENCLOSURE/Residential/patio-res2.jpg"
import gimg54 from "../asset/img/Gallery/RETRACTABLE PATIO ENCLOSURE/Residential/patio-res3.jpg"
import gimg55 from "../asset/img/Gallery/RETRACTABLE PATIO ENCLOSURE/Residential/patio-res4.jpg"
import gimg56 from "../asset/img/Gallery/RETRACTABLE PATIO ENCLOSURE/Residential/patio-res5.jpg"
import gimg57 from "../asset/img/Gallery/RETRACTABLE PATIO ENCLOSURE/Residential/patio-res6.jpg"
import gimg58 from "../asset/img/Gallery/RETRACTABLE PATIO ENCLOSURE/Residential/patio-res7.jpg"

import gimg59 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res1.jpg"
import gimg60 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res2.jpg"
import gimg61 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res3.jpg"
import gimg62 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res4.jpg"
import gimg63 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res5.jpg"
import gimg64 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res6.jpg"
import gimg65 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res7.jpg"
import gimg66 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res8.jpg"
import gimg67 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res9.jpg"
import gimg68 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res10.jpg"
import gimg69 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res11.jpg"
import gimg70 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res12.jpg"
import gimg71 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res13.jpg"
import gimg72 from "../asset/img/Gallery/RETRACTABLE ROOFS/Residential/ret-res14.jpg"

import gimg73 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res1.jpg"
import gimg74 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res2.jpg"
import gimg75 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res3.jpg"
import gimg76 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res4.jpg"
import gimg77 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res5.jpg"
import gimg78 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res6.jpg"
import gimg79 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res7.jpg"
import gimg80 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res8.jpg"
import gimg81 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res9.jpg"
import gimg82 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res10.jpg"
import gimg83 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res11.jpg"
import gimg84 from "../asset/img/Gallery/TELESCOPIC POOL ENCLOSURE/Residence/pool-res12.jpg"
import { useLocation } from "react-router-dom";

const pageName = "Gallery > Images"
export default function Images() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>

      {/* <Banner/> */}
      <TopBanner sendData={pageName} />
      <div className="container">
        {/* -----------------------Tabs------------------- */}
        <div className="d-block">
          <h1 className="text-center text-til mb-md-3">IMAGES</h1>
          <Tabs defaultActiveKey="first" className="bg-white nav-tab">
            <Tab eventKey="first" title="Commercial" className="my-3 my-md-5">
              {/* ---------------events---------------- */}

              <div className="container events">
                <div className="row">
                  {imagesComData.map((comData, cindex) => {
                    return (
                      <>
                        <div className="col-md-4 mb-3 mb-md-5 bg-light" key={cindex}>
                          <div className="card-img events-img events-container shadow">
                            <img src={comData.image_pic} alt="..." />
                            <div class="events-overlay">
                              <div class="events-text">{comData.image_description}</div>
                            </div>
                            <div className="card-title text-center py-2">
                              <h6>{comData.image_name}</h6>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })}

                </div>
              </div>
            </Tab>
            <Tab eventKey="second" title="Residential" className="my-3 my-md-5">
              {/* ---------------events---------------- */}

              <div className="container events">
                <div className="row">
                  {imagesResData.map((resData, rindex) => {
                    return (
                      <>
                        <div className="col-md-4 mb-3 mb-md-5 bg-light" key={rindex}>
                          <div className="card-img events-img events-container shadow">
                            <img src={resData.image_pic} alt="..." />
                            <div class="events-overlay">
                              <div class="events-text">{resData.image_description}</div>
                            </div>
                            <div className="card-title text-center py-2">
                              <h6>{resData.image_name}</h6>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })}

                </div>
              </div>
            </Tab>

          </Tabs>
        </div>


        {/* ------------------------------------tab-end---------- */}
      </div>


    </div>
  )
}
