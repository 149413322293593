import React from "react";
import Banner from "./home-components/CarouselBanner";
import OwlTheme from "./home-components/OwlTheme.jsx";
import ShortAboutMega from "./home-components/ShortAboutMega";
import FaqHome from "./home-components/FaqHome";
import { useLocation } from "react-router-dom";

export default function Home() {
   const location = useLocation();
   React.useEffect(() => {
     window.scrollTo(0, 0);
   }, [location]);
   return (
      <div>
         <Banner />
         <ShortAboutMega />
         <OwlTheme />
         <FaqHome />
      </div>
   );
}
