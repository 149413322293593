import React from "react";
import "./visionMission.css";
import { Container, Col, Row } from "react-bootstrap";

export default function visionMission() {
  return (
    <div>
      <Container fluid className="py-5" id="vision-mission">
        <div className="d-none d-md-block" id="vision-float-div"></div>
        <Row className="my-2 my-md-5">
          <Col>
            <h2 className="">VISION AND MISSION</h2>
            {/* <br />
            <h3 className="text-center ft-darkks">Vision To Improve Our Products</h3> */}
          </Col>
        </Row>
        <Row className="vission-mission-card justify-content-center">
          <Col md={3} className=" text-center m-2 m-md-5 " id="vm-card1">
            <div className=" circle-icon rounded-circle mb-3">
              <span
                className="iconify"
                data-icon="eva:eye-fill"
                data-inline="false"
              ></span>
            </div>
            <h4 className="`ft-darkks`">Vision</h4>
            <p>
              “ Aspires to be a global leader in retractable roof & enclosure solutions with
              high quality services leading to customer delight and value for
              money “.
            </p>
          </Col>

          <Col md={3} className=" text-center m-2 m-md-5 " id="vm-card2">
            <div className=" circle-icon rounded-circle mb-3">
              <span
                className="iconify"
                data-icon="fluent:target-32-regular"
                data-inline="false"
              ></span>
            </div>
            <h4 className="ft-darkks">Mission</h4>
            <p>
              “To constantly enhance quality through integration of design,
              innovation and customer centric creative engineering”.
            </p>
          </Col>

          <Col md={3} className=" text-center m-2 m-md-5 " id="vm-card3">
            <div className=" circle-icon rounded-circle mb-3">
              <span
                className="iconify"
                data-icon="fa-solid:medal"
                data-inline="false"
              ></span>
            </div>
            <h4 className="ft-darkks">Position & Achievement</h4>
            <p>
              <ul>
                <li>
                  Largest Manufacturer of Retractable roofs in the country.
                  Implemented the largest Retractable roof solution in the
                  country.
                </li>
              </ul>
            </p>
          </Col>
        </Row>
        <div className="d-none d-md-block" id="mission-float-div"></div>
      </Container>
    </div>
  );
}
