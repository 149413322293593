import React from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import "./Videos.css"
import Banner from "../home-components/CarouselBanner";
import videosComData from "../json/VideosCom.json";
import videosResData from "../json/VideosRes.json";
import TopBanner from "../home-components/HeaderContent.jsx";
import { useLocation } from "react-router-dom";
export default function Videos() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  const pageName = "Gallery > Videos"
  return (
    <div>
      {/* <Banner/> */}
      <TopBanner sendData={pageName} />
      {/* --------------------tabs---------------------- */}

      <div className="container">
        <div className="d-block">
          <h2 className="text-center text-til mb-md-3">VIDEOS</h2>
          <Tabs defaultActiveKey="first" className="bg-white nav-tab">
            <Tab eventKey="first" title="Commercial" className="my-3 my-md-5">
              <div className="container mb-4">
                <div className="row">
                  {videosComData.map((comData, cindex) => {
                    return (
                      <>
                        <div className="col-md-4 mb-2" key={cindex}>
                          <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
                            <iframe
                              width="100%"
                              height="240"
                              src={comData.vid_src}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                            <div id="Having-text-vide">
                              <span>
                                {comData.vid_title}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })}

                </div>
              </div>
            </Tab>
            <Tab eventKey="second" title="Residential" className="my-3 my-md-5">
              <div className="container mb-4">
                <div className="row">
                  {videosResData.map((resData, cindex) => {
                    return (
                      <>
                        <div className="col-md-4 mb-2" key={cindex}>
                          <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
                            <iframe
                              width="100%"
                              height="240"
                              src={resData.vid_src}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                            <div id="Having-text-vide">
                              <span>
                                {resData.vid_title}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })}


                </div>
              </div>
            </Tab>

          </Tabs>
        </div>
        {/* ----------------------tabs-end--------------------------- */}



      </div>


    </div>
  )
}
