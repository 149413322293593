import React from "react";
import "./Contact.css";
import { Container, Row, Col } from "react-bootstrap";
import Banner from "../home-components/CarouselBanner";
import TopBanner from "../home-components/HeaderContent.jsx";
import { useLocation } from "react-router-dom";
export default function Contact() {
    const pageName = "Contact us";

    const location = useLocation();
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            <TopBanner sendData={pageName} />
            {/* <Banner/> */}
            <Container fluid id="contact-page">
                <Container>
                    <h1 className="text-center mt-sm-5 mt-3">Fill this form</h1>
                    <br />
                    <Row>
                        <Col md={4} className="mx-2 mx-sm-0">
                            <div className="d-flex">
                                <i class="fas fa-map-marker-alt"></i>&nbsp;
                                <p>
                                    #119/1, Forest Gate,
                                    <br />
                                    Machohalli, Dasanapura Hobli,
                                    <br />
                                    Magadi Main Road,
                                    <br />
                                    Bangalore 560091
                                </p>
                            </div>
                            <div className="d-flex">
                                <i class="fas fa-phone-alt"></i>&nbsp;
                                <p>
                                    +91 9972963229 <br /> +91 9342161305
                                </p>
                            </div>
                            <div className="d-flex">
                                <i class="fas fa-envelope-open"></i>&nbsp;
                                <p>
                                    md@megavent.in
                                    <br /> info@megavent.in
                                </p>
                            </div>
                        </Col>

                        <Col md={7} className="mx-2 mx-sm-0">
                            <form class="row g-3" name="reg" onsubmit="" action="" method="">
                                <div class="col-md-6">
                                    <label for="fname" class="form-label">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="fname"
                                        name="cfname"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="lname" class="form-label">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="lname"
                                        name="clname"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="inputEmail4" class="form-label">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        id="idemail"
                                        name="cemail"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="mob" class="form-label">
                                        Phone No.
                                    </label>
                                    <input
                                        type="tel"
                                        class="form-control"
                                        id="idmob"
                                        name="cphone"
                                    />
                                </div>

                                <div class="col-12 mb-3">
                                    <label for="text-area" class="form-label">
                                        Message
                                    </label>
                                    <textarea
                                        class="form-control"
                                        aria-label="With textarea"
                                    ></textarea>
                                </div>
                                <div class="col-12 d-flex">
                                    <button type="submit" class="btn  mx-2">
                                        Submit
                                    </button>
                                    <button type="reset" class="btn ">
                                        Reset
                                    </button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid id="have-map">
                <Row>
                    <Col className="mt-5 p-0">
                        <iframe
                            title="map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62209.274443243456!2d77.36678381902402!3d12.966754795622034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3b8c3aee632b%3A0xf1663d0374edacd0!2sMegavent%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1625558291341!5m2!1sen!2sin"
                            allowfullscreen=""
                            loading="lazy"
                        ></iframe>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
