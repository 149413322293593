import React, { useEffect } from "react";
// import Navbar from "react-bootstrap/Navbar";
// import {Link} from "react-router-dom";
import logo from "../../asset/img/home/logo.png";
import Navmenu from ".././../json/navabar.json";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const Navigation = () => {
  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };
    const changeNav = document.getElementById("navabrHeader");
    function myFunction() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        changeNav.classList.add(`bg-darkk`);
        changeNav.classList.remove(`mt-md-4`);
      } else {
        changeNav.classList.remove(`bg-darkk`);
        changeNav.classList.add(`mt-md-4`);
      }
    }
  });
  return (
    <div>
      <Container>
        <Row id="full-Header-Contact">
          <Col md={{ span: 7, offset: 5 }}>
            <div className="email-phone float-right">
              <span>
                <a href="mail:info@example.com">info@megavent.in</a>
              </span>
              <span>
                <a href="tel: 9342161305">+91&nbsp;9342161305</a>
              </span>
              <span>
                <a href="tel: 9972963229">+91&nbsp;9972963229</a>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
      <nav
        expand="lg"
        className="navbar fixed-top navbar-expand-lg navbar-dark mt-md-4"
        id="navabrHeader"
      >
        <div class="container-fluid px-md-5">
          <Link class="navbar-brand" to="/" id="nav">
            <div className="text-center">
              <img src={logo} alt="loading issue" />
            </div>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
              {Navmenu.map((navtitle, index) => {
                return (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  <div className="d-flex">
                    <li>
                      <Link to={navtitle.link} key={index}>
                        {navtitle.title}
                      </Link>
                    </li>
                    {navtitle.submenu.length !== 0 ? (
                      <li className="nav-item dropdown">
                        <Link
                          class="nav-link dropdown-toggle p-0 pl-2 pt-1"
                          to="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* Dropdown */}
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {navtitle.submenu.map((option, id) => {
                            return (
                              <>
                                <li>
                                  <Link
                                    class="dropdown-item"
                                    to={option.sublink}
                                    key={id}
                                  >
                                    {option.subtitle}
                                  </Link>
                                </li>
                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </li>
                    ) : null}
                    </div>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
