import React from 'react';
import TopNav from "./home-components/navigation/TopNav";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUs from "./AboutUs";
import Project from "./home-components/Project";
import Home from "./home"
import "./App.css"
import Footer from "./Footer";
import Products from "./Product-component/Products";
import Product from "./Product-component/Product";
import RetractableRoof from "./Product-component/SingleProductRetRoof";
import Projects from "./Projects/Projects";
import Contact from "./contact/Contact";
import Images from "./Galley-components/Images";
import Videos from "./Galley-components/Videos";
import Blogs from "./Blogs-components/Blogs";
import Widget from "./Widget-component/Widget";
import Faq from "./FaqMain/FaqMain";
import Error from "./Error";

function App() {
  // React.useEffect(() => {
  //   const loader = document.getElementById("preloader");
  //   window.onload = () => {
  //     setTimeout(function () {
  //       loader.style.display = "none";
  //     }, 2000)
  //   }
  // })
  return (
    <>
      {/* <div id="preloader"></div> */}
      <Router>
        <TopNav />
        <Widget />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={AboutUs} />

          <Route exact path="/products" component={Products} />
          <Route exact path="/products/product/:id" component={Product} />

          <Route exact path="/projects" component={Projects} />
          <Route exact path="/projects/project/:id" component={Project} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/gallery/images" component={Images} />
          <Route exact path="/gallery/videos" component={Videos} />
          <Route exact path="/blogs" component={Blogs} />
          <Route exact path="/faq" component={Faq} />
          <Route component={Error} />
        </Switch>
        <Footer/>
      </Router>

      {/* <Switch><Route path="/" component={} /></Switch> */}
    </>
  );
}

export default App;
