import React from "react";
// import Banner from "./CarouselBanner";
import "./Project.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import contentImg from "../asset/img/banner/banner2.jpg";
import projectData from "../json/Project.json";
import { useParams } from "react-router-dom";
import TopBanner from "../home-components/HeaderContent.jsx";
import { useLocation } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
const Project = () => {
  const { id } = useParams();
  const projectArray = [];
  const projectList = projectData.find((x) => x.id == id);
  projectArray.push(projectList);
  const pageName = projectArray[0].projectHeading;

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div>
      {/* <Banner /> */}
      <TopBanner sendData={pageName} />
      <Container fluid id="project-main-div">
        <div className="d-none d-md-block" id="project-float-div"></div>

        {projectArray.map((data, index) => {
          return (
            <>
              <Container id="project" key={index}>
                <Row>
                  <Col xs={12}>
                    <div className="heading text-center">
                      {/* <h1>{data.projectHeading}</h1> */}
                      <p className="ft-darkk">
                        Explore our wide range of products for all needs
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row
                  id="content-having"
                  className="justify-content-center align-items-center mt-sm-5 mt-2"
                >
                  <Col sm={12} md={5}>
                    <div className="card-text mb-sm-3 mb-2">
                      <h3>Location</h3>
                      <p>{data.location}</p>
                      <h3>Year</h3>
                      <p>{data.year}</p>
                    </div>
                    {/* <div className="card2-text mb-sm-3 mb-2">
              <h3>Requirement</h3>
              <p>
                {data.requirment}
              </p>
            </div> */}
                    <div className="card-text3 mb-sm-3 mb-2">
                      <h3>Dimension</h3>
                      <p>{data.dimension}</p>
                    </div>
                  </Col>
                  <Col sm={12} md={7}>
                    <iframe
                      id="project-iframe"
                      src={data.rightVid}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-megyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </Col>
                </Row>
                <Row id="body-text">
                  <Col xs={12}>
                    <div className="card-heading-text mt-md-3 mt-2">
                      <h3 className="mb-4 text-til">Project Description</h3>
                      <p>{data.projectDescription_para1}</p>
                      <p>{data.projectDescription_para2}</p>
                    </div>
                  </Col>
                </Row>

                {data.img_card.length !== 0 ? (
                  <div>
                    <Row id="having-someCard" className="my-sm-5 my-2">
                      {data.img_card.map((imgdata, imgid) => {
                        return (
                          <>
                            <Col
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              className="mb-2"
                              key={imgid}
                            >
                              <Card>
                                <Card.Img
                                  variant="top"
                                  src={imgdata.bottom_img}
                                />
                                <Card.Body className="text-center">
                                  <Card.Title>
                                    {imgdata.bottom_img_title}
                                  </Card.Title>
                                  <Card.Text>
                                    {imgdata.bottom_img_text}
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </div>
                ) : null}
              </Container>
            </>
          );
        })}
        <div className="d-none d-md-block" id="project-down-float-div"></div>
      </Container>
    </div>
  );
};

export default Project;
