import React from 'react'
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap'
import "./FaqMain.css"
import Banner from "../home-components/CarouselBanner";
import { useLocation } from "react-router-dom";
import TopBanner from "../home-components/HeaderContent.jsx";
export default function FaqMain() {
    const pageName = "FAQs"

    const location = useLocation();
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    return (
        <div>
            {/* <Banner/> */}
            <TopBanner sendData={pageName} />
            <Container className="my-4">
                <h1 className="text-center text-til mb-4">FAQs</h1>
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" className="bg-light-til">
                                    &#x270D; What is the warranty of Polycarbonate sheet?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>Warranty of Polycarbonate sheet is 10 years.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1" className="bg-light-til">
                                    &#x270D; How strong is the polycarbonate sheet in safety aspects?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>The imported Polycarbonate sheet we use is 12 times tougher than toughened glass.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2" className="bg-light-til">
                                    &#x270D; What is the thickness of the polycarbonate sheet?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        Polycarbonate sheet we use is mostly compact… thickness depends on the size of the enclosure and it starts from ….
                                        <ul>
                                            <li>3mm thick for upto 4meters span,</li>
                                            <li>4mm thick for 4meters to 12 meters and</li>
                                            <li>6mm thick for above 12 meters span.</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3" className="bg-light-til">
                                    &#x270D; What is the warranty of aluminum profile?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>Warranty of Aluminum profile is 30 years and 15 years for powder coating.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4" className="bg-light-til">
                                    &#x270D; What are the various shades available in aluminum profile?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>Shades available in aluminum profiles are white, silver, anthracite, beige & wood imitation… where only White and Silver are standard colors. Any other color is on extra chargeable basis</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="5" className="bg-light-til">
                                    &#x270D; What is the color variant available in polycarbonate sheet?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>The polycarbonate sheets we use are – clear compact, clear bronze, bronze embossed, clear embossed.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="6" className="bg-light-til">
                                    &#x270D; What is the overall life of the product?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body> Overall life of the product is 25 – 30 years with regular Annual Preventive Maintenence.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="7" className="bg-light-til">
                                    &#x270D; What is the minimum Height of the enclosure?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="7">
                                    <Card.Body>Minimum height of the enclosure is 500mm. Span depends upon the width of the enclosure. If possible can be customized to lower heights too.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="8" className="bg-light-til">
                                    &#x270D; What is the space required to place for track?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="8">
                                    <Card.Body>Track width is calculated depending on the no of segments… for upto 10 meters span roof, each track is 100mm.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="9" className="bg-light-til">
                                    &#x270D; Is the Roof 100% leak-proof?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="9">
                                    <Card.Body>Yes, it is 100% leak-proof.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="10" className="bg-light-til">
                                    &#x270D; What is the warranty of the motor?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="10">
                                    <Card.Body> Warranty of the motor is of one year.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="11" className="bg-light-til">
                                    &#x270D; What is the free maintenance period of the enclosure?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="11">
                                    <Card.Body>Free maintenance period of any of Megavent’s enclosure is one year.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
