import React from "react";
import "./Products.css";
import { Container, Col, Row } from "react-bootstrap";
import Banner from "../home-components/CarouselBanner";
import TopBanner from "../home-components/HeaderContent.jsx";
// import roof from "../asset/img/home/roof.jpg";
import pro1 from "../asset/img/products/roof.jpg";
import pro2 from "../asset/img/products/patio.jpg";
import pro3 from "../asset/img/products/dom.jpg";
import pro4 from "../asset/img/products/pool.jpg";
import pro5 from "../asset/img/products/heavy.jpg";
import pro6 from "../asset/img/products/fixed.jpg";
import { useLocation, Link } from "react-router-dom";

export default function Products() {
  const pageName = "OUR PRODUCTS";
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      {/* <Banner /> */}
      <TopBanner sendData={pageName} />
      <Container fluid className="products-heading">
        <Row>
          <Col className="my-2 text-center">
            <h2 className="ft-darkks">Explore our wide range of products</h2>
          </Col>
        </Row>
      </Container>

      <Container fluid id="products-container-fluid">
        <div className="d-none d-md-block" id="products-float-div-left"></div>
        <div className="d-none d-md-block" id="products-float-div-right"></div>
        <div className="container" id="having-card">
          <div className="row need-border mb-2">
            <div className="col  my-md-5 my-3">
              <div class="card shadow">
                <div class="row g-0">
                  <div class="col-md-12">
                    <div class="card-body">
                      <h3 class="card-title text-til text-center">
                        {" "}
                        RETRACTABLE ROOFS & ENCLOSURES{" "}
                      </h3>
                      <p class="card-text">
                        Human Beings have evolved thus far with the best of
                        indoor living spaces for residential as well as
                        commercial purposes. There is other 2 types of living
                        spaces, which is “Indoors as Outdoors” and “Outdoors as
                        Indoors”
                      </p>
                      <p class="card-text">
                        <b>Indoors as Outdoors</b> - Mostly in tropical regions,
                        there is a concept of having sunlight come into the
                        living area. This was an age-old concept in such
                        regions, where-in the residential and commercial spaces
                        would have open courtyards in the centre of the
                        buildings, so that the indoors are kept bright and less
                        humid. But one disadvantage of such courtyards was the
                        entire area becomes messy during rains and
                        thunderstorms. To protect these areas from such bad
                        weather, people started using fixed glass skylights.
                        These Glass Skylights did not do any help with the Humid
                        and heat trap conditions, where as it was only providing
                        sunlight.
                      </p>
                      <p class="card-text">
                        <b>Megavent’s Retractable Roof</b> is the best alternate
                        to such fixed Glass skylights. These unique movable
                        roofs provide both sunlight as well as protection from
                        rough climatic conditions by just keeping it open or
                        close whenever needed with a click of a button.
                      </p>
                      <p className="card-text">
                        <b>Outdoors as Indoors</b> - These are the unique areas
                        just outside our living spaces which may be gardens,
                        patios, balconies, etc. And these may be in both
                        residences and commercial spaces like Hotels,
                        Restaurants, Resorts, Staycations, Offices, etc. We
                        enjoy using these outdoor spaces only when the climate
                        is conducive. When the weather turns rough, we leave
                        these spaces and rush to close-by secure indoors. This
                        is where <b>Megavent’s</b> robust and strong{" "}
                        <b>Retractable Enclosures</b> becomes very useful. It
                        allows people to relax and use the space without
                        bothering much about the weather conditions. With just
                        click of a button, the entire outdoor space becomes
                        indoor, providing the much relief from the harsh climate
                        without much of an effort and in no time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row need-border mb-2">
            <div className="col  my-md-5 my-3">
              <div class="card shadow">
                <div class="row g-0">
                  <div class="col-md-5">
                    <img
                      src={pro1}
                      class="img-fluid shadow rounded-start"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h3 class="card-title text-til">RETRACTABLE ROOF</h3>
                      <p class="card-text">
                        Retractable roofs are the best solution to cover
                        courtyards, atriums, indoor pool areas ,open coffee
                        shops, Bars and Restaurants, outdoor recreation areas
                        ,Terraces and the possibilities are plenty.The
                        retractable roofs allows you access much needed natural
                        sunlight and ventilation at the same time providing
                        complete protection from rains and bad weather
                        conditions. The Retractable roofs can be stopped at any
                        desired position or can be completely opened.
                        <br /> The retractable roofs are specifically designed
                        as per the site requirement. It is a custom built
                        product to provide a functional solution with enhanced
                        aesthetic appeal to suit the architectural settings.
                      </p>
                      <div className="btn offset-md-4" id="products-know-more">
                        <Link to="/products/product/1">Know More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col need-border mb-2">
              <div class="card shadow p-0 my-md-5 my-3">
                <div class="row g-0">
                  <div class="col-md-7 order-1 order-md-0">
                    <div class="card-body">
                      <h3 class="card-title text-til">
                        RETRACTABLE PATIO ENCLOSURE
                      </h3>
                      <p class="card-text">
                        No need to worry about rain and weather changes any
                        more. During Nice weather you can completely open it to
                        enjoy the space and during Rains and Bad weather you can
                        close it and still enjoy the space. Megavent Retractable
                        Patio enclosures are an excellent solutions to convert
                        outdoor spaces like Terraces, Balconies , Patios, Garden
                        into a safe and elegant functional spaces . With this
                        unique product, we allow homes to be extended beyond
                        your dreams. Imagine having a clean outdoor space
                        attached to the home where you could relax with nature
                        with total protection from rain and bad weather also
                        allow your kids and pets to play without security
                        concerns. This product will also beautify an open
                        terrace restaurant and also increase the footfall during
                        rainy days . It also gives extra protection to all the
                        outdoor furniture because of the UV coated polycarbonate
                        sheets. Our Patio are also customized according to your
                        needs just like our other products.
                      </p>
                      <div className="btn offset-md-4" id="products-know-more">
                        <Link to="/products/product/2">Know More</Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 order-0 order-md-1">
                    <img
                      src={pro2}
                      class="img-fluid shadow rounded-start"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col need-border mb-2">
              <div class="card shadow p-0 my-md-5 my-3">
                <div class="row g-0">
                  <div class="col-md-7 order-1 order-md-0">
                    <div class="card-body">
                      <h3 class="card-title text-til">
                        TELESCOPIC SWIMMING POOL ENCLOSURE
                      </h3>
                      <p class="card-text">
                        Telescopic swimming pool enclosures are designed to
                        cater various needs such as safety for Kids and pets, to
                        prevent dust and debris, for privacy and protection from
                        harmful UV radiation. Telescopic swimming pool
                        enclosures bring an extended swimming season. Complete
                        maintenance free solution & improves aesthetics of the
                        surroundings. We also offer Flat sliding deck covers for
                        smaller swimming pools. This completely covers the
                        swimming pool when not in use and the top of the deck
                        can be used for any outdoor activity or recreation. The
                        finish of the sliding deck is custom built as per
                        customer’s requirement.
                      </p>
                      <div className="btn offset-md-4" id="products-know-more">
                        <Link to="/products/product/4">Know More</Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 order-0 order-md-1">
                    <img
                      src={pro4}
                      class="img-fluid shadow rounded-start"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col need-border mb-2">
              <div class="card shadow p-0 my-md-5 my-3">
                <div class="row g-0">
                  <div class="col-md-7 order-1 order-md-0">
                    <div class="card-body">
                      <h3 class="card-title text-til">FIXED SKYLIGHT</h3>
                      <p class="card-text">
                        Telescopic swimming pool enclosures are designed to
                        cater various needs such as safety for Kids and pets, to
                        prevent dust and debris, for privacy and protection from
                        harmful UV radiation. Telescopic swimming pool
                        enclosures bring an extended swimming season. Complete
                        maintenance free solution & improves aesthetics of the
                        surroundings. We also offer Flat sliding deck covers for
                        smaller swimming pools. This completely covers the
                        swimming pool when not in use and the top of the deck
                        can be used for any outdoor activity or recreation. The
                        finish of the sliding deck is custom built as per
                        customer’s requirement.
                      </p>
                      <div className="btn offset-md-4" id="products-know-more">
                        <Link to="/products/product/6">Know More</Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 order-0 order-md-1">
                    <img
                      src={pro6}
                      class="img-fluid shadow rounded-start"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row need-border mb-2">
            <div className="col  my-md-5 my-3">
              <div class="card shadow">
                <div class="row g-0">
                  <div class="col-md-5">
                    <img
                      src={pro3}
                      class="img-fluid shadow rounded-start"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h3 class="card-title text-til">DOME ENCLOSURE</h3>
                      <p class="card-text">
                        Dome Enclosures are beautifully designed light
                        structures to enjoy the outside from the inside all year
                        round. A separate room for sitting in the open. They are
                        multipurpose portable Dome enclosures that will
                        transform your outside area and create a very stylish
                        space. They are multipurpose and can be used as a Spa,
                        Private Dining Area, relaxation room, entertainment,
                        WFH, Exercise room, Pet home and much more. They are
                        built to withstand wind, snow, rain, and bad weather
                        like thunder storms and cyclones. It ensures an
                        unobstructed 360 panoramic view, as well as a view of
                        the sky above. These are perfect alternatives to highly
                        expensive structures.The unique Dome enclosures are not
                        only a fabulous addition to private gardens but are also
                        used by pubs, hotels, restaurants, care homes and other
                        commercial ventures to monetise outdoor areas. It can be
                        easily installed and also relocated within a short time.
                        They are a quirky and cost effective alternative to a
                        permanent structure.
                      </p>
                      <div className="btn offset-md-4" id="products-know-more">
                        <Link to="/products/product/3">Know More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row need-border mb-2">
            <div className="col  my-md-5 my-3">
              <div class="card shadow">
                <div class="row g-0">
                  <div class="col-md-5">
                    <img
                      src={pro5}
                      class="img-fluid shadow rounded-start"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-7">
                    <div class="card-body">
                      <h3 class="card-title text-til">
                        HEAVY DUTY GARDEN UMBERELLA
                      </h3>
                      <p class="card-text">
                        Dome Enclosures are beautifully designed light
                        structures to enjoy the outside from the inside all year
                        round. A separate room for sitting in the open. They are
                        multipurpose portable Dome enclosures that will
                        transform your outside area and create a very stylish
                        space. They are multipurpose and can be used as a Spa,
                        Private Dining Area, relaxation room, entertainment,
                        WFH, Exercise room, Pet home and much more. They are
                        built to withstand wind, snow, rain, and bad weather
                        like thunder storms and cyclones. It ensures an
                        unobstructed 360 panoramic view, as well as a view of
                        the sky above. These are perfect alternatives to highly
                        expensive structures.The unique Dome enclosures are not
                        only a fabulous addition to private gardens but are also
                        used by pubs, hotels, restaurants, care homes and other
                        commercial ventures to monetise outdoor areas. It can be
                        easily installed and also relocated within a short time.
                        They are a quirky and cost effective alternative to a
                        permanent structure.
                      </p>
                      <div className="btn offset-md-4" id="products-know-more">
                        <Link to="/products/product/5">Know More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
