import React from "react";
import "./Management.css";
import roof from "../asset/img/home/story.jpg";
import { Container, Col, Row } from "react-bootstrap";

export default function Management() {
  return (
    <div>
      <Container fluid className="management-container-fluid py-3">
        <Container>
          <Row className="management-aboutus my-5">
            <Col lg={5} id="management-about-img">
              <img src={roof} alt="..." className="shadow" />
            </Col>

            <Col
              lg={7}
              className="p-2 p-md-5 mt-2 shadow"
              id="management-aboutus-right"
            >
              <h1 className="">OUR STORY</h1>
              <br />
              <p>
                Megavent has more than 600 installation across India. Most
                commercial properties are using their top floor all year round
                because of the reliable retractable roof solutions. Since these
                roofs are easily openable, it’s safe and secure for people using
                the terrace in case of emergencies like fire.
              </p>
              <p>
                Megavent has a strong and well-trained team to carry out sales,
                installation and maintenance of our products across India,
                middle east and UK.
              </p>
              <p>
                Megavent manages the enterprise sales by creating and supporting
                the channel partner network across it’s territory.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
