import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container, Col, Row } from "react-bootstrap";
import pro1 from "../asset/img/home/product-crausel/retractable-roof.jpeg";
import pro2 from "../asset/img/our-product-home/ret-patio.jpeg";
import pro3 from "../asset/img/our-product-home/pool.jpg";
import pro4 from "../asset/img/home/product-crausel/fixed-skylight.jpg";
import pro5 from "../asset/img/our-product-home/dom.jpg";
import pro6 from "../asset/img/home/product-crausel/heavy-duty.jpg";
import comProject1 from "../asset/img/projects/comercial/AES-New-Delhi.jpg";
import comProject2 from "../asset/img/projects/comercial/Hotel-LA-New-Delhi.jpg";
import comProject3 from "../asset/img/projects/comercial/Hotel-Poinisuk-shillong.jpg";
import comProject4 from "../asset/img/projects/comercial/Levels-Restobar-Bengaluru.jpg";
import comProject5 from "../asset/img/projects/comercial/RESERVOIR-BENGALURU.jpg";
import comProject6 from "../asset/img/projects/comercial/Sri-Venkateswara-temple-TTD-Tirumala.jpg";
import resProject1 from "../asset/img/projects/residential/EXTENROOF-BENGALURU.jpg";
import resProject2 from "../asset/img/projects/residential/EXTENROOF-MUMBAI.jpg";
import resProject3 from "../asset/img/projects/residential/FALCON-RETRACTABLE-ROOF-HYDERABAD.jpg";
import resProject4 from "../asset/img/projects/residential/HI-POOL ENCLOSURE-SHARJAH.jpg";
import resProject5 from "../asset/img/projects/residential/HI-POOL-ENCLOSURE-ABU-DHABI.jpg";
import resProject6 from "../asset/img/projects/residential/RETRACTABLE-PATIO-ENCLOSURE-LONAVALA.jpg";
import "./Testimonial.css";
import "./OurProjectsOwl.css";
import ins from "../asset/indian-navy.jpeg";
const OwlTheme = () => {
  const [value, setValue] = useState(4);
  const [valuetwo, setValuetwo] = useState(3);
  useEffect(() => {
    window.onload = () => {
      if (window.screen.width < 992) {
        setValue(2);
        setValuetwo(2);
        if (window.screen.width < 576) {
          setValue(1);
          setValuetwo(1);
        }
      } else {
        setValue(4);
        setValuetwo(3);
      }
    };
  }, []);

  return (
    <>
      {/* ----------------------products craousel------------------- */}
      <div className="container-fluid" id="themeOwl" data-aos="fade-up">
        <div className="text-white text-center">
          <h1>PRODUCTS</h1>
          <h6 className="text-white">Explore our range of Enclosure</h6>
        </div>
        <OwlCarousel
          loop={true}
          items={value}
          autoplay={true}
          autoplayTimeout={4000}
          className="owl-theme container"
          margin={15}
        >
          <div className="item text-center">
            <div className="card">
              <div className="card-img p-2">
                <img src={pro1} alt="not available" />
              </div>
              <div className="card-heading-h6">
                <Link to="/products/product/1">
                  <h6 className="text-center">RETRACTABLE ROOFS</h6>
                </Link>
              </div>
              <div className="card-text">
                <p className="px-2">
                  <span
                    className="iconify"
                    data-icon="akar-icons:double-check"
                    data-inline="false"
                  ></span>
                  &nbsp; Experience the outdoors in the comfort of your home
                  with just a click
                </p>
              </div>
            </div>
          </div>
          <div className="item text-center">
            <div className="card">
              <div className="card-img p-2">
                <img src={pro2} alt="not available" />
              </div>
              <div className="card-heading-h6">
                <Link to="/products/product/2">
                  <h6 className="text-center">RETRACTABLE PATIO ENCLOSURES </h6>
                </Link>
              </div>
              <div className="card-text">
                <p className="px-2">
                  <span
                    className="iconify"
                    data-icon="akar-icons:double-check"
                    data-inline="false"
                  ></span>
                  &nbsp; Extend your living space into the nature.
                </p>
              </div>
            </div>
          </div>
          <div className="item text-center">
            <div className="card">
              <div className="card-img p-2">
                <img src={pro3} alt="not available" />
              </div>
              <div className="card-heading-h6">
                <Link to="/products/product/4">
                  <h6 className="text-center">
                    TELESCOPIC SWIMMING POOL ENCLOSURES
                  </h6>
                </Link>
              </div>
              <div className="card-text">
                <p className="px-2">
                  <span
                    className="iconify"
                    data-icon="akar-icons:double-check"
                    data-inline="false"
                  ></span>
                  &nbsp;Total pool, human & pet protection
                </p>
              </div>
            </div>
          </div>
          <div className="item text-center">
            <div className="card">
              <div className="card-img p-2">
                <img src={pro4} alt="not available" />
              </div>
              <div className="card-heading-h6">
                <Link to="/products/product/6">
                  <h6 className="text-center">FIXED SKYLIGHTS</h6>
                </Link>
              </div>
              <div className="card-text">
                <p className="px-2">
                  <span
                    className="iconify"
                    data-icon="akar-icons:double-check"
                    data-inline="false"
                  ></span>
                  &nbsp;Open feeling of space
                </p>
              </div>
            </div>
          </div>
          <div className="item text-center">
            <div className="card">
              <div className="card-img p-2">
                <img src={pro5} alt="not available" />
              </div>
              <div className="card-heading-h6">
                <Link to="/products/product/3">
                  <h6 className="text-center">DOME ENCLOSURES </h6>
                </Link>
              </div>
              <div className="card-text">
                <p className="px-2">
                  <span
                    className="iconify"
                    data-icon="akar-icons:double-check"
                    data-inline="false"
                  ></span>
                  &nbsp; Cozy private space in the open
                </p>
              </div>
            </div>
          </div>
          <div className="item text-center">
            <div className="card">
              <div className="card-img p-2">
                <img src={pro6} alt="not available" />
              </div>
              <div className="card-heading-h6">
                <h6 className="text-center">GARDEN UMBRELLA</h6>
              </div>
              <div className="card-text">
                <p className="px-2">
                  <span
                    className="iconify"
                    data-icon="akar-icons:double-check"
                    data-inline="false"
                  ></span>
                  &nbsp;Sunshine or a drizzle, stay relax outdoors
                </p>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>

      {/* -------------------------testimonial carousel-------------------- */}
      <Container
        fluid
        className="py-5 "
        id="testimonial-home"
        data-aos="fade-up"
      >
        <div
          className="d-none d-sm-block"
          id="testimonial-home-float-div"
        ></div>
        <Row>
          <Col>
            <h1 className="">CUSTOMER'S TESTIMONIAL</h1>
            <br />
            <h3 className="text-center ft-darkk">
              Customers who got valuable service from us
            </h3>
          </Col>
        </Row>
      </Container>

      <OwlCarousel
        loop={true}
        items={valuetwo}
        autoplay={true}
        autoplayTimeout={4000}
        className="owl-theme container"
        margin={15}
        id="testimonial-owl"
      >
        <div className="item">
          <div className="card">
            <div className="card-body">
              <div className="card-icon text-center">
                <span
                  className="iconify"
                  data-icon="foundation:comment-quotes"
                  data-inline="false"
                ></span>
              </div>
              <p>
                <img src={ins} />
              </p>
              <small>
                <p className="text-center">Indian Navy PFR 2022</p>
              </small>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="card" style={{ overflow: "scroll" }}>
            <div className="card-body">
              <div className="card-icon text-center">
                <span
                  className="iconify"
                  data-icon="foundation:comment-quotes"
                  data-inline="false"
                ></span>
              </div>
              <p>
                I found Megavent after scouring the internet for a retractable
                roof manufacturer in Bangalore. During my search and after
                meeting some other vendors, I realized that Megavent are the
                only genuine makers of retractable roofs in Bangalore with a
                proper manufacturing and engineering facility. The owner
                Purushotham was accommodating of my requests for some unusual
                additions to their original design and that was a clincher for
                me. Covid issues affected some aspects of the delivery and
                installation but Megavent made temporary arrangements to tide
                over the situation. I have found Purushotham and his team to be
                quite customer centric and sensitive to customer needs. Overall,
                I have had a good experience working with Megavent.
              </p>
              <small>
                <p className="text-center">Rehan Shaikh</p>
              </small>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="card">
            <div className="card-body">
              <div className="card-icon text-center">
                <span
                  className="iconify"
                  data-icon="foundation:comment-quotes"
                  data-inline="false"
                ></span>
              </div>
              <p>
                The best movable roofing system, seen ever in terms of quality,
                functioning, aesthetic and elegance. Have positive solution to
                every situation we ask for. Thankful and see forward to engage
                Megavent for our upcoming projects.
              </p>
              <small>
                <p className="text-center">
                  Praveen Kumar Tirumalasetty- Bengaluru.
                </p>
              </small>
            </div>
          </div>
          {/* <div className="card-icon2 text-center">
            <i className="fab fa-twitter"></i>
            <br />
            <small>
              <p>Praveen Kumar Tirumalasetty- Bengaluru.</p>
            </small>
          </div> */}
        </div>
        <div className="item">
          <div className="card">
            <div className="card-body">
              <div className="card-icon text-center">
                <span
                  className="iconify"
                  data-icon="foundation:comment-quotes"
                  data-inline="false"
                ></span>
              </div>
              <p>
                Excellent work, prompt service, I have got installed 34 ft. X 14
                ft sliding roof with remote control operated, 1.5 years ago, I
                am very much satisfied with the work, service, quick response,
                overall excellent
              </p>
              <small>
                <p className="text-center">Mukeshkumar Thakkar- Pune.</p>
              </small>
            </div>
          </div>
          {/* <div className="card-icon2 text-center">
            <i className="fab fa-twitter"></i>
            <br />
            <small>
            <p className="text-right">Mukeshkumar Thakkar- Pune.</p>
            </small>
          </div> */}
        </div>
        <div className="item">
          <div className="card">
            <div className="card-body">
              <div className="card-icon text-center">
                <span
                  className="iconify"
                  data-icon="foundation:comment-quotes"
                  data-inline="false"
                ></span>
              </div>
              <p>
                Great – Product,Value for money,After Service ,Supervosion by
                Experts,Less Maintenance That's what I can say about this
                product.. I strongly recommend this product if anyone interested
                but have to shed a bit of extra money!!!
              </p>
              <small>
                <p className="text-center">Somiron Hajong, Shillong </p>
              </small>
            </div>
          </div>
          {/* <div className="card-icon2 text-center">
            <i className="fab fa-twitter"></i>
            <br />
            <small>
              <p>Somiron Hajong, Shillong </p>
            </small>
          </div> */}
        </div>
        <div className="item">
          <div className="card">
            <div className="card-body">
              <div className="card-icon text-center">
                <span
                  className="iconify"
                  data-icon="foundation:comment-quotes"
                  data-inline="false"
                ></span>
              </div>
              <p>
                Megavent Retractable roofs gave us the perfect solution to
                render this place one with nature and also accessible at all
                time saving installing megavent Retractable roofs our space is
                open to sky we are one with nature and yet commercially
                accessible and it has become any weather in all better space
              </p>
              <small>
                <p className="text-center">
                  WANDERERS CRAFT BREWERY, Bangalore
                </p>
              </small>
            </div>
          </div>
          {/* <div className="card-icon2 text-center">
            <i className="fab fa-twitter"></i>
            <br />
            <small>
              <p>WANDERERS CRAFT BREWERY, Bangalore</p>
            </small>
          </div> */}
        </div>
      </OwlCarousel>

      {/* ----------------------------our project craousel------------------------ */}
      <div className="container mt-5" data-aos="fade-up">
        <div className="text-til text-center py-5">
          <h1>OUR PROJECTS</h1>
          {/* <h6 className="text-warning">explore out range of retractable roof</h6> */}
        </div>
        {/* -----------------tabs------------- */}
        <div className="d-block">
          <Tabs defaultActiveKey="first" className="bg-white nav-tab">
            <Tab eventKey="first" title="Commercial">
              {/* ----------content-------- */}
              <div className="container-fluid py-5 " id="our-projects-owl">
                <OwlCarousel
                  loop={true}
                  items={value}
                  autoplay={true}
                  autoplayTimeout={4000}
                  className="owl-theme container"
                  margin={15}
                >
                  <div className="item box">
                    <img src={comProject1} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">AES, New Delhi</span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={comProject2} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">Hotel LA, New Delhi</span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={comProject3} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">Hotel Poinisuk, shillong</span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={comProject4} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">Levels Restobar, Bengaluru</span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={comProject5} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">RESERVOIR, BENGALURU</span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={comProject6} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">Sri Venkateswara temple</span>
                    </div>
                  </div>
                </OwlCarousel>
              </div>

              {/* --------------content-end------------- */}
            </Tab>
            <Tab eventKey="second" title="Residential">
              {/* ----------content-------- */}
              <div className="container-fluid py-5" id="our-projects-owl">
                <OwlCarousel
                  loop={true}
                  items={value}
                  autoplay={true}
                  autoplayTimeout={4000}
                  className="owl-theme container"
                  margin={15}
                >
                  <div className="item box">
                    <img src={resProject1} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">EXTENROOF, BENGALURU</span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={resProject2} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">EXTENROOF, MUMBAI</span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={resProject3} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">
                        FALCON RETRACTABLE ROOF, HYDERABAD
                      </span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={resProject4} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">HI POOL ENCLOSURE, SHARJAH</span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={resProject5} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">HI POOL ENCLOSURE, ABU DHABI</span>
                    </div>
                  </div>
                  <div className="item box">
                    <img src={resProject6} alt="not available" />
                    <div className="box-content">
                      <h3 className="title">Project</h3>
                      <span className="post">
                        RETRACTABLE PATIO ENCLOSURE, LONAVALA
                      </span>
                    </div>
                  </div>
                </OwlCarousel>
              </div>

              {/* --------------content-end------------- */}
            </Tab>
          </Tabs>
        </div>

        {/* -----------------tabs-end------------- */}
      </div>
    </>
  );
};

export default OwlTheme;
