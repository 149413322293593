import React, { useState, useEffect } from "react";
import "./Product.css";
import { Container, Col, Row } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import "./SingleProductRetRoof.css";
import spectra from "../asset/img/products/spectra.jpg";
import spectra1 from "../asset/img/products/spectra1.jpg";
import slidon from "../asset/img/products/slidon1.jpg";
import slidon1 from "../asset/img/products/retRoof/slidon1.jpg";
import extenroof from "../asset/img/products/extenroof1.jpg";
import exten1 from "../asset/img/products/retRoof/exten1.jpeg";
import falcon from "../asset/img/products/falcon.jpg";
import falcon2 from "../asset/img/products/falcon2.jpg";
import extensit from "../asset/img/products/extensit.jpg";
import extensit1 from "../asset/img/products/extensit2.jpg";
import xlsit from "../asset/img/products/xlsit1.jpg";
import xlsit2 from "../asset/img/products/xlsit2.jpg";
import high_pool from "../asset/img/products/pool/high_pool.jpg";
import high_pool1 from "../asset/img/products/pool/high_pool1.jpg";
import low_pool from "../asset/img/products/lowpool.jpg";
import low_pool1 from "../asset/img/products/lowpool1.jpg";
import slidingdeck from "../asset/img/products/slidingdeck.jpg";
import slidingdeck1 from "../asset/img/products/slidingdeck2.jpg";
import table from "../asset/img/products/umberella/table2.jpg";
import octron from "../asset/img/products/octron.jpg";
import octron2 from "../asset/img/products/octron_1.jpg";
import fixedlight from "../asset/img/products/fixedlight.jpg";
import fixedlight1 from "../asset/img/products/fixedlight1.jpg";
import fixedlight2 from "../asset/img/products/fixedlight2.jpg";
import TopBanner from "../home-components/HeaderContent.jsx";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import productData from "../json/Product.json";
import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
export default function Product() {
  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const { id } = useParams();
  const productArray = [];
  const productList = productData.find((x) => x.id == id);
  productArray.push(productList);

  const [value, setValue] = useState(4);

  useEffect(() => {
    window.onload = () => {
      if (window.screen.width < 992) {
        setValue(2);

        if (window.screen.width < 576) {
          setValue(1);
        }
      } else {
        setValue(4);
      }
    };
  });

  console.log(high_pool);
  console.log(high_pool1);
  return (
    <div>
      {productArray.map((data, index) => {
        return (
          <>
            {/* <Banner /> */}
            <TopBanner sendData={data.product_name} key={index} />
            <Container fluid className="Single-product">
              <Container className=" py-4 mt-5">
                <div className="d-none d-md-block" id="si-pro-float-div"></div>
                <h2 className="text-center ft-darkk">
                  Explore our wide range of products
                </h2>

                <Row className="my-4">
                  <Col md={6} id="si-pro-left-img">
                    {/* <img src={roof} alt="not available" className="shadow" /> */}
                    <iframe
                      id="iframe"
                      src={data.product_video_src}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </Col>
                  <Col md={6} id="si-pro-right-content">
                    <h2 className="text-til">{data.product_models}</h2>
                    <div className="mt-4 ">
                      <p className="ft-darkk">{data.product_content}</p>
                      <strong>
                        <ol>
                          <a href="#model1">
                            <li>{data.product_model_1}</li>
                          </a>
                          <a href="#model2">
                            <li>{data.product_model_2}</li>
                          </a>
                          <a href="#model3">
                            <li>{data.product_model_3}</li>
                          </a>
                          <a href="#model4">
                            <li>{data.product_model_4}</li>
                          </a>
                          <a href="#model5">
                            <li>{data.product_model_5}</li>
                          </a>
                        </ol>
                      </strong>
                    </div>

                    {/* <div className="d-flex justify-content-center ">
                        <div className="btn btn-md-lg  px-md-5 py-md-3" id="si-pro-enquire">Enquire Now <b>&gt;</b></div>
                    </div> */}
                  </Col>
                </Row>
                <Row className="my-5 " id="si-pro-down-left-content">
                  <Col className="p-3 ">
                    <h3 className="text-til">{data.product_features}</h3>
                    <ul>
                      {data.features.length !== 0 ? (
                        <div>
                          {data.features.map((fdata, fid) => {
                            return (
                              <>
                                <li key={fid}>
                                  <div className="d-flex">
                                    <div>
                                      <i class="fas fa-angle-double-right text-til"></i>
                                    </div>
                                    <div className="ml-2">
                                      <span>{fdata.feature}</span>
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </div>
                      ) : null}
                    </ul>
                    <h3 className="text-til">{data.product_benifits}</h3>
                    <ul>
                      {data.benifits.length !== 0 ? (
                        <div>
                          {data.benifits.map((bdata, bid) => {
                            return (
                              <>
                                <li key={bid}>
                                  <div className="d-flex">
                                    <div>
                                      <i class="fas fa-angle-double-right text-til"></i>
                                    </div>
                                    <div className="ml-2">
                                      <span>{bdata.benifit}</span>
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </div>
                      ) : null}
                    </ul>
                  </Col>
                  {/* <Col md={6} id="si-pro-down-left-vid">
                        <iframe id="iframe"  src="https://www.youtube.com/embed/Qf_RMtpuHXc?autoplay=1&mute=1&color=white"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </iframe> 

                    </Col> */}
                </Row>

                <div
                  className="d-none d-md-block"
                  id="si-pro-down-float-div"
                ></div>
              </Container>

              {/* ------------------------product type section -------------------- */}

              {data.product_model_page.length !== 0 ? (
                <div>
                  {data.product_model_page.map((mdata, mid) => {
                    return (
                      <>
                        <div
                          className="container-fluid bg-light my-2 py-5"
                          id={mdata.navigation_id}
                          key={mid}
                        >
                          <div className="container " id="product-type">
                            <h3 className="text-til text-center mb-sm-5 mb-3 product-title-upper">
                              {mdata.product_model_heading}
                            </h3>
                            <div className="row">
                              <div className="col-md-4 col-sm-6 col-12 order-1 order-md-0">
                                <img src={mdata.product_model_img1} alt="" />
                              </div>

                              <div className="col-md-4 col-sm-6 col-12 order-3 order-md-1 ">
                                <iframe
                                  id="iframe1"
                                  src={mdata.product_model_vid1}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen
                                ></iframe>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12 order-4 order-md-2 ">
                                <iframe
                                  id="iframe2"
                                  src={mdata.product_model_vid2}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen
                                ></iframe>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12 order-2 order-md-3">
                                <img src={mdata.product_model_img2} alt="" />
                              </div>

                              <div className="col-md-4 col-sm-6 col-12 my-md-5 my-2 order-0 order-md-4">
                                <h5 className="ft-darkk">
                                  {mdata.product_model_text}
                                </h5>
                              </div>

                              <div className="col-md-4 col-sm-6 col-12 order-5">
                                <iframe
                                  id="iframe3"
                                  src={mdata.product_model_vid3}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : null}
              {/* ----------------extra img for umbrellapage--------------- */}
              <div className="container-fluid text-center umbrella">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <img src={data.types_table} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              {/* ---------------------------owl---------------------- */}
              <div className="container my-4 py-2" id="my-owl">
                <h3 className="text-til text-center my-3">
                  {data.product_gallery_heading}
                </h3>
                <div className="row">
                  <div className="col">
                    <OwlCarousel
                      loop={true}
                      items={value}
                      autoplay={true}
                      autoplayTimeout={4000}
                      className="owl-theme container"
                      margin={15}
                    >
                      <div className="item boxes">
                        <Link to="/gallery/images">
                          <img src={data.product_gallery_img1} alt="" />
                        </Link>
                      </div>
                      <div className="item boxes">
                        <Link to="/gallery/images">
                          <img src={data.product_gallery_img2} alt="" />
                        </Link>
                      </div>
                      <div className="item boxes">
                        <Link to="/gallery/images">
                          <img src={data.product_gallery_img3} alt="" />
                        </Link>
                      </div>
                      <div className="item boxes">
                        <Link to="/gallery/images">
                          <img src={data.product_gallery_img4} alt="" />
                        </Link>
                      </div>
                      <div className="item boxes">
                        <Link to="/gallery/images">
                          <img src={data.product_gallery_img5} alt="" />
                        </Link>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </Container>
          </>
        );
      })}
    </div>
  );
}
